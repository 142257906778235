@use 'styles/colors.scss';
@use 'styles/constants.scss';

.status-dot {
  border-radius: 10rem;

  &.large {
    height: 3rem;
    width: 3rem;
  }

  &.small {
    height: 1rem;
    width: 1rem;
  }

  &.medium {
    height: 2rem;
    width: 2rem;
  }

  // &.custom-size {
  //   // The custom size styles can be added based on your specific requirements
  // }

  &.success {
    background-color: colors.$coreSuccess;
  }

  &.warning {
    background-color: colors.$coreWarning;
  }

  &.default {
    background-color: colors.$neutralsGreyLight;
  }
}
