@import 'styles/colors.scss';
@import 'styles/constants.scss';

.flightroom-item-container {
  display: flex;
  flex-direction: column;
  padding: $m 1.2rem;
  background-color: $coreWhite100;
  cursor: pointer;
  border-left: 0.8rem solid transparent;
  border-right: 0.8rem solid transparent;

  &.alternative-color {
    background-color: $aviobookWebSecondaryVictorVictor02;
  }

  &.selected {
    background-color: $aviobookWebAccentRomeo02;
    border-left: 0.8rem solid $aviobookWebAccentRomeo07;
  }

  &.isDisabled {
    cursor: not-allowed;
  }

  .flightroom-item-top-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    align-items: center;
    position: relative;

    .flightroom-item-top-left {
      flex-grow: 1;
    }

    .flightroom-item-top-middle {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-start;
    }

    .flightroom-item-top-right {
      position: absolute;
      right: 0;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .flightroom-item-unread-dot {
        align-self: center;
        background-color: $coreAttention;
        border-radius: $m;
        height: 2rem;
        display: flex;
        justify-content: center;
        padding: 0 0.6rem;
      }
    }
  }

  .flightroom-item-center-container {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    margin-bottom: $l;

    .flightroom-item-center {
      display: flex;
      flex-direction: column;
      align-items: center;

      .flightroom-item-center-date {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: $s;
        margin-top: $s;

        > .flightroom-item-line {
          display: flex;
          flex: 1;
          height: 0.1rem;
          width: 4.5rem;
          border-color: $aviobookWebPrimaryZulu02;
          color: $aviobookWebPrimaryZulu02;
          background-color: $aviobookWebPrimaryZulu02;
          height: 0.1rem;
        }

        .flightroom-item-line-left {
          margin-right: 2rem;
          top: $l;
        }
        .flightroom-item-line-right {
          margin-left: 2rem;
          top: $l;
        }
      }
    }

    .flightroom-item-time-slot {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .flightroom-item-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .flightroom-item-bottom-left-text {
      align-items: center;
      display: inline-flex;
      flex-direction: row;
    }
  }
}
