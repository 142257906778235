@use 'styles/constants';

i.icon {
  display: inline-block;
  margin: 0;

  svg {
    overflow: visible;
  }

  &.custom-color {
    path {
      fill: currentColor;
    }
  }
}

.button-icon {
  margin-right: 0.8rem;
}

.right {
  background-color: transparent;
;
}