@use 'styles/colors.scss';
@use 'styles/constants.scss';

.tags-container {
  display: flex;
  overflow-x: auto;
  padding-bottom: constants.$m;

  .tags-button {
    background-color: colors.$aviobookDarkSecondaryHotel;
    border-radius: 0.4rem;
    padding: 0.2rem 0.5rem;

    &:not(:last-child) {
      margin-right: 0.5rem; /* Adjust the margin value as needed */
    }
  }
}
