@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import '../_styles/breakpoints';
@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/reset';

html {
  // This value defines the value of '1rem'
  font-size: 10px;
}

html,
body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
}

h3 {
  font-size: 2rem;
  font-weight: bold;
}

h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

h5 {
  font-size: 1.6rem;
  font-weight: bold;
}

h6 {
  font-size: 1.4rem;
  font-weight: 500;
}

body {
  font-size: $font-size;
}

a {
  color: $primary-color;
  text-decoration: none;
  &:hover {
    color: darkenOnHover($primary-color);
  }
  &.disabled {
    pointer-events: none;
    opacity: $disabled-opacity;
  }
}

button {
  font-family: $font-family;
}

button.plain {
  border: none;
  background: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}