// TODO: uncomment this when taggable roles should be enabled again.

// @use 'styles/colors.scss';
// @use 'styles/constants.scss';

// .message-bubble-avatar-name {
//   display: flex;
//   align-items: center;
//   margin-bottom: constants.$s;
//   white-space: pre;
//   margin-left: calc(3.7rem + constants.$m); // = avatar width + margin
// }

// .message-bubble-container {
//   display: flex;

//   .avatar {
//     margin-top: -3rem;
//   }

//   .message-bubble-content-container {
//     max-width: 80%;
//     display: flex;
//     flex-direction: column;
//     margin-left: constants.$m;
//     padding: constants.$m constants.$l;
//     border-radius: 0.4rem;
//     position: relative;
//     background-color: colors.$aviobookDarkNeutralsLight;

//     &.has-attachment {
//       max-width: 50%;
//     }

//     .message-bubble-image-button-wrapper {
//       all: unset;
//       cursor: pointer;

//       img {
//         max-width: 100%;
//         max-height: 100%;
//         margin: constants.$s 0;
//       }
//     }

//     .message-bubble-hyperlink-text {
//       text-decoration: underline;
//       color: inherit;
//     }

//     .message-bubble-tagged-role {
//       // TODO: check with designers if this needs to change color's
//       background-color: colors.$aviobookLightPrimaryAlpha;
//       padding: 0.2rem 1rem;
//       border-radius: 1.2rem;
//     }

//     &:after {
//       content: '';
//       position: absolute;
//       right: calc(100% - 0.5rem); /*i.e. half the height*/
//       top: constants.$m;
//       height: constants.$m;
//       width: constants.$m;
//       background: inherit;
//       transform: rotate(45deg);
//     }

//     &:not(.has-avatar) {
//       margin-left: calc(3.7rem + constants.$m); // = avatar width + margin
//     }

//     &.by-me {
//       margin-right: constants.$m;
//       margin-left: auto;
//       background-color: colors.$aviobookWebAccentRomeo07;

//       &:after {
//         left: calc(100% - 0.5rem);
//       }
//     }
//   }

//   .text-date {
//     margin-top: constants.$s;
//     align-self: flex-end;
//   }
// }

@use 'styles/colors.scss';
@use 'styles/constants.scss';

.message-bubble-avatar-name {
  display: flex;
  align-items: center;
  margin-bottom: constants.$s;
  white-space: pre;
  margin-left: calc(3.7rem + constants.$m); // = avatar width + margin
}

.message-bubble-container {
  display: flex;

  .avatar {
    margin-top: -3rem;
  }

  .message-bubble-content-container {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: constants.$m;
    padding: constants.$m constants.$l;
    border-radius: 0.4rem;
    position: relative;
    background-color: colors.$aviobookDarkNeutralsLight;

    &.has-attachment {
      max-width: 50%;
    }

    .message-bubble-image-button-wrapper {
      all: unset;
      cursor: pointer;

      img {
        max-width: 100%;
        max-height: 100%;
        margin: constants.$s 0;
      }
    }

    .message-bubble-hyperlink-text {
      text-decoration: underline;
      color: inherit;
    }

    &:after {
      content: '';
      position: absolute;
      right: calc(100% - 0.5rem); /*i.e. half the height*/
      top: constants.$m;
      height: constants.$m;
      width: constants.$m;
      background: inherit;
      transform: rotate(45deg);
    }

    &:not(.has-avatar) {
      margin-left: calc(3.7rem + constants.$m); // = avatar width + margin
    }

    &.by-me {
      margin-right: constants.$m;
      margin-left: auto;
      background-color: colors.$aviobookWebAccentRomeo07;

      &:after {
        left: calc(100% - 0.5rem);
      }
    }
  }

  .text-date {
    margin-top: constants.$s;
    align-self: flex-end;
  }
}
