@use 'styles/colors';
@use 'styles/constants';

.page-content-block-container {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  .page-content-block-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;
  }

  .page-content-block-content {
    background-color: colors.$coreWhite100;
    padding: constants.$l;
    border-radius: 0.4rem;
  }
}
