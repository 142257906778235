@use 'styles/colors';
@import './_styles/constants';

:root {
  --toastify-font-family: $font-family;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: colors.$semanticSuccess;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: colors.$semanticWarning;
}

.toast {
  border-radius: 0;
  min-height: 4rem;

  .toast-body {
    .toast-message-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .toast-message-time {
        opacity: 0.7;
      }
    }
  }
}
