@import 'styles/colors.scss';
@import 'styles/constants.scss';

.license-agreement-container {
  display: flex;
  flex-direction: column;
  background-color: $aviobookLightPrimaryBravo;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.license-agreement-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $aviobookLightPrimaryAlpha;
  overflow-y: auto;
  width: 100%;

  .license-agreement-content-container-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 660px;
    width: 100%;
    padding: 75px $xl;
  }

  .title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: $m;
  }

  .subtitle {
    text-align: center;
    margin-bottom: $xl;
  }
}

.license-agreement-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: $aviobookDarkNeutralsLight;

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-right: $xl;

    .checkbox-label {
      margin-left: $m;
    }
  }

  .license-agreement-checkbox-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 660px;
    padding: 20px $xl;
    width: 100%;
  }
}
