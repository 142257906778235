@use 'styles/colors.scss';
@use 'styles/constants.scss';

.web-container {
  height: 100vh;
  background-color: colors.$aviobookLightPrimaryBravo;
  display: flex;
  justify-content: center;
}

.web-content-container {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .login-forgot-password-text {
    margin-top: 2.5rem;
    text-align: center;
  }

  &.confirmation {
    max-width: 36rem;
    text-align: center;
  }

  .form-fieldset {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: constants.$m;
  }

  .input-field {
    width: 32rem;
    margin-bottom: constants.$m;
  }

  .confirmation-title {
    margin-top: 4rem;
    margin-bottom: constants.$l;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 32rem;

    .button {
      width: 20rem;
      display: block;
      margin-top: constants.$m;
    }

    .login-input-error-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 constants.$s;
      margin-bottom: 3.35rem;

      .login-input-error-message {
        margin-left: constants.$xs;
      }
    }
  }
}
