@use 'styles/colors';
@use 'styles/constants';

.page-content-wrapper {
  display: flex;
  width: 100%;
  gap: 0;
  flex-direction: row;
  background-color: colors.$coreWhite100;

  > * {
    flex-grow: 1;
  }
}
