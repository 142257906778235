@use 'styles/constants.scss';
@use 'styles/colors.scss';

.empty-chat-container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform: scaleY(-1);

  .empty-chat-text-container {
    margin-top: 4rem;
    text-align: center;
    :nth-child(2) {
      margin-top: 1rem;
    }
  }

  .empty-chat-image {
    display: flex;
    justify-content: center;
  }
}
