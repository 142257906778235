@use 'styles/colors';
@use 'styles/constants';

.unread-indicator {
  background-color: #cbdbe1;
  padding: constants.$s constants.$m;
  margin: 0 auto;
  margin-bottom: constants.$m;
  margin-top: constants.$m;
}
