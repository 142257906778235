.chatbot-container {
  .chatbot-container-top {
    display: flex;
    justify-content: flex-end;
  }

  > :not(:first-child) {
    margin-top: 3.9rem;
  }
}

.right-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-margin {
  padding-right: 1rem;
}
