@use 'styles/colors';
@use 'styles/constants';

.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: constants.$s;
  left: constants.$m;
}

.input-clear-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: constants.$s;
  right: constants.$m;
}

.input {
  width: 100%;
  outline: none;
  text-align: left;
  padding: 1rem 1.4rem;
  background: colors.$coreWhite100;
  border: 0.1rem solid colors.$aviobookCMSOutlineGrey;
  color: colors.$aviobookDarkSecondaryHotel;
  border-radius: 0.4rem;
  box-sizing: border-box;
  height: 3.5rem;

  &::placeholder {
    color: colors.$aviobookWebPrimaryZulu04;
  }

  &.error {
    border: solid 0.1rem colors.$coreWarning;
  }

  &.disabled {
    background-color: colors.$aviobookCMSDisabledInputfield;
    color: colors.$aviobookCMSDisabledInputfieldPlaceholder;
  }

  &.hasIcon {
    padding-left: 4.5rem;
  }

  &.hasClearButton {
    padding-right: 4.5rem;
  }

  &::placeholder {
    color: colors.$aviobookWebPrimaryZulu04;
  }
}
