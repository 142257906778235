@use 'styles/colors';
@use 'styles/constants';

@mixin nav-bar-styles($background-color, $border-color, $padding) {
  display: flex;
  flex-direction: column;
  background-color: $background-color;
  padding: $padding;
}

@mixin nav-bar-ul($border-color, $margin) {
  .nav-bar-sub-ul {
    display: inline-flex;
    color: colors.$aviobookLightPrimaryAlpha;
    margin-top: $margin;

    .nav-bar-sub-li {
      text-transform: uppercase;

      :hover {
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: constants.$xxl;
      }

      &.isActiveTab {
        border-bottom: 0.2rem solid $border-color;
      }
    }
  }
}

.top-nav-bar {
  @include nav-bar-styles(colors.$coreWhite100, colors.$aviobookWebAccentRomeo07, constants.$l constants.$m 0 constants.$l);
  @include nav-bar-ul(colors.$aviobookWebAccentRomeo07, 0rem);
}

.nav-bar {
  @include nav-bar-styles(colors.$aviobookLightPrimaryBravo, colors.$aviobookWebAccentRomeo07, constants.$l constants.$m constants.$xxs constants.$xxl);
  @include nav-bar-ul(colors.$aviobookWebAccentRomeo07, constants.$xl);
}
