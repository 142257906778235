@use 'styles/colors';
@use 'styles/constants';

.about-container {
    border-right: 0.1rem solid colors.$aviobookLightPrimaryBravo;
    height: 100vh;
    overflow: hidden;

    .about-header {
        display: flex;
        justify-content: space-between;
        padding: constants.$l;

        .header-equalizer-div {
            width: 2.5rem
        }
    }
    .about-version-container {
        background-color: colors.$aviobookWebSecondaryVictorVictor03;
        padding: constants.$l;
    }
    
    .about-licenses-container {
        padding: constants.$l;
        
        .about-licenses-text {
            margin-bottom: constants.$m;
        }
        //TODO: I dont like this solution, but feel like I am wasting time on this
        .about-licenses-list {
            overflow-y: auto;
            height: 83vh;
            padding-bottom: 4rem
        }
    }
}

.license-item-container {
    margin-bottom: constants.$m;
}

