@use 'styles/colors.scss';
@use 'styles/constants.scss';

.notification-center-item-container {
  all: unset;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: constants.$xl constants.$m;
  background-color: colors.$coreWhite100;
  border-top: 0.1rem solid colors.$aviobookWebSecondaryVictorVictor02;
  border-bottom: 0.1rem solid colors.$aviobookWebPrimaryZulu01;

  .notification-center-item-status {
    margin-right: constants.$m;

    .notification-center-item-status-dot {
      // We set this margin-top to center the dot and text. (based on line-height 19.6)
      margin-top: 0.5rem;
      width: constants.$m;
      height: constants.$m;
      border-radius: 10rem;
      background-color: colors.$aviobookWebAccentRomeo07;
    }
  }

  .notification-center-item-text-container {
    .notification-center-item-notification-created-at {
      margin-top: constants.$s;
    }
  }
}
