@use 'styles/constants.scss';
@use 'styles/colors.scss';

.chat-header-container {
  height: 4.5rem;
  display: flex;
  align-items: center;
  background-color: colors.$aviobookDarkNeutralsLight;
  justify-content: space-between;
  padding: 1.4rem 1.2rem;

  .chat-header-left {
    display: flex;
    flex: 1;
  }
  .chat-header-middle {
    display: flex;
    flex: 3;
    justify-content: center;
    align-items: center;

    > .chat-header-title {
      margin-right: constants.$l;
    }
  }
  .chat-header-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .chat-header-notification-icon {
      background-color: transparent;
      > :first-child {
        position: absolute;
        top: 0.2rem;
        margin-left: 1.2rem;
      }
    }

    > :not(:last-child) {
      margin-right: 2rem;
    }
  }
}
