/* DO NOT EDIT, AUTOMATICALLY GENERATED */
$aviobookCMSDisabledInputfield: #eef1f3;
$aviobookCMSDisabledInputfieldPlaceholder: #668798;
$aviobookCMSTableRow: #fcfcfc;
$aviobookCMSLightBlue: #486f84;
$aviobookCMSOutlineGrey: #a1b5c0;
$aviobookCMSInfoText: #7e9aa8;
$aviobookCMSDisabledButton: #d3eef9;
$aviobookDarkLegacyMainAlpha: rgb(10, 44, 62);
$aviobookDarkLegacyMainBravo: rgb(15, 56, 77);
$aviobookDarkLegacyMainCharlie: rgb(17, 64, 88);
$aviobookDarkLegacyMainDelta: rgb(35, 83, 108);
$aviobookDarkLegacyMainEcho: rgb(55, 98, 120);
$aviobookDarkLegacyMainFoxtrot: rgb(0, 132, 200);
$aviobookDarkLegacyMainGolf: rgb(40, 73, 90);
$aviobookDarkLegacyMainHotel: rgb(57, 142, 174);
$aviobookDarkLegacyMainIndia: rgb(75, 124, 150);
$aviobookDarkLegacyMainLima: rgb(174, 199, 213);
$aviobookDarkLegacyMainPrimary: rgb(79, 189, 230);
$aviobookDarkLegacyNeutralsDark: rgb(0, 0, 0);
$aviobookDarkLegacyNeutralsGreyDark: rgb(153, 153, 153);
$aviobookDarkLegacyNeutralsGreyLight: rgb(211, 211, 211);
$aviobookDarkLegacyNeutralsLight: rgb(255, 255, 255);
$aviobookDarkLegacySecondaryFoxtrot: rgb(190, 204, 212);
$aviobookDarkLegacySemanticAttention: rgb(244, 223, 71);
$aviobookDarkLegacySemanticCaution: rgb(244, 142, 49);
$aviobookDarkLegacySemanticImported: rgb(210, 166, 248);
$aviobookDarkLegacySemanticSuccess: rgb(37, 193, 100);
$aviobookDarkLegacySemanticUnclear: rgb(115, 249, 255);
$aviobookDarkLegacySemanticWarning: rgb(211, 35, 54);
$aviobookDarkNeutralsLight: rgb(255, 255, 255);
$aviobookDarkPrimaryAlpha: #0a2c3e;
$aviobookDarkPrimaryAlpha01: rgb(10,44,62,01);
$aviobookDarkPrimaryBravo: rgb(15,56,77);
$aviobookDarkPrimaryCharlie: #114058;
$aviobookDarkPrimaryDelta: #23536c;
$aviobookDarkPrimaryEcho: #376278;
$aviobookDarkSecondaryAlpha: #28495a;
$aviobookDarkSecondaryBravo: #0084c8;
$aviobookDarkSecondaryCharlie: #398eae;
$aviobookDarkSecondaryDelta: #4fbde6;
$aviobookDarkSecondaryEcho: #4b7c96;
$aviobookDarkSecondaryFoxtrot: #beccd4;
$aviobookDarkSecondaryGolf: #aec7d5;
$aviobookDarkSecondaryHotel: rgb(20,71,97);
$aviobookDarkSemanticCaution: rgb(244, 142, 49);
$aviobookLight20AccentRomeo06: rgb(79, 189, 230);
$aviobookLight20NeutralsLightYuma01White: rgb(255, 255, 255);
$aviobookLightLegacyPrimaryAlpha: rgb(20, 71, 97);
$aviobookLightLegacyPrimaryBravo: rgb(221, 233, 240);
$aviobookLightPrimaryAlpha: #144761;
$aviobookLightPrimaryBravo: #dde9f0;
$aviobookWebAccentRomeo01: rgb(246, 252, 254);
$aviobookWebAccentRomeo02: rgb(238, 249, 253);
$aviobookWebAccentRomeo03: rgb(211, 238, 249);
$aviobookWebAccentRomeo04: rgb(194, 232, 246);
$aviobookWebAccentRomeo05: rgb(97, 196, 233);
$aviobookWebAccentRomeo06: rgb(79, 189, 230);
$aviobookWebAccentRomeo07: rgb(71, 170, 207);
$aviobookWebAccentRomeo08: rgb(67, 161, 196);
$aviobookWebAccentRomeo09: rgb(59, 142, 172);
$aviobookWebAccentRomeo10: rgb(47, 113, 138);
$aviobookWebAccentRomeo11: rgb(32, 76, 92);
$aviobookWebNeutralsDarkDark01: rgb(140, 140, 140);
$aviobookWebNeutralsDarkDark010: rgb(26, 26, 26);
$aviobookWebNeutralsDarkDark011: rgb(13, 13, 13);
$aviobookWebNeutralsDarkDark02: rgb(128, 128, 128);
$aviobookWebNeutralsDarkDark03: rgb(115, 115, 115);
$aviobookWebNeutralsDarkDark04: rgb(102, 102, 102);
$aviobookWebNeutralsDarkDark05: rgb(89, 89, 89);
$aviobookWebNeutralsDarkDark06: rgb(77, 77, 77);
$aviobookWebNeutralsDarkDark07: rgb(64, 64, 64);
$aviobookWebNeutralsDarkDark08: rgb(51, 51, 51);
$aviobookWebNeutralsDarkDark09: rgb(38, 38, 38);
$aviobookWebNeutralsDarkDark12Black: rgb(0, 0, 0);
$aviobookWebNeutralsLightLight010: rgb(178, 178, 178);
$aviobookWebNeutralsLightLight011: rgb(166, 166, 166);
$aviobookWebNeutralsLightLight012: rgb(153, 153, 153);
$aviobookWebNeutralsLightLight01White: rgb(255, 255, 255);
$aviobookWebNeutralsLightLight02: rgb(249, 249, 249);
$aviobookWebNeutralsLightLight03: rgb(242, 242, 242);
$aviobookWebNeutralsLightLight04: rgb(235, 235, 235);
$aviobookWebNeutralsLightLight05: rgb(229, 229, 229);
$aviobookWebNeutralsLightLight06: rgb(217, 217, 217);
$aviobookWebNeutralsLightLight07: rgb(209, 209, 209);
$aviobookWebNeutralsLightLight08: rgb(199, 199, 199);
$aviobookWebNeutralsLightLight09: rgb(189, 189, 189);
$aviobookWebPrimaryZulu01: rgb(208, 218, 223);
$aviobookWebPrimaryZulu02: rgb(196, 209, 215);
$aviobookWebPrimaryZulu03: rgb(161, 181, 192);
$aviobookWebPrimaryZulu04: rgb(126, 154, 168);
$aviobookWebPrimaryZulu05: rgb(102, 135, 152);
$aviobookWebPrimaryZulu06: rgb(79, 117, 137);
$aviobookWebPrimaryZulu07: rgb(55, 98, 121);
$aviobookWebPrimaryZulu08: rgb(20, 71, 97);
$aviobookWebPrimaryZulu10: rgb(16, 57, 78);
$aviobookWebPrimaryZulu9: rgb(17, 60, 83);
$aviobookWebSecondaryTangoTango01: rgb(249, 250, 251);
$aviobookWebSecondaryTangoTango02: rgb(220, 228, 232);
$aviobookWebSecondaryTangoTango03: rgb(226, 232, 235);
$aviobookWebSecondaryTangoTango04: rgb(226, 232, 235);
$aviobookWebSecondaryTangoTango05: rgb(197, 209, 216);
$aviobookWebSecondaryTangoTango06: rgb(150, 173, 184);
$aviobookWebSecondaryTangoTango07: rgb(139, 164, 177);
$aviobookWebSecondaryUniformUniform01: rgb(253, 254, 255);
$aviobookWebSecondaryUniformUniform02: rgb(251, 254, 255);
$aviobookWebSecondaryUniformUniform03: rgb(248, 252, 254);
$aviobookWebSecondaryUniformUniform04: rgb(240, 249, 253);
$aviobookWebSecondaryUniformUniform05: rgb(240, 249, 253);
$aviobookWebSecondaryUniformUniform06: rgb(228, 245, 251);
$aviobookWebSecondaryUniformUniform07: rgb(226, 244, 251);
$aviobookWebSecondaryVictorVictor01: rgb(247, 248, 248);
$aviobookWebSecondaryVictorVictor02: rgb(245, 247, 248);
$aviobookWebSecondaryVictorVictor03: rgb(242, 245, 247);
$aviobookWebSecondaryVictorVictor04: rgb(238, 244, 247);
$aviobookWebSecondaryVictorVictor05: rgb(230, 239, 244);
$aviobookWebSecondaryVictorVictor06: rgb(224, 235, 241);
$aviobookWebSecondaryVictorVictor07: rgb(221, 233, 240);
$aviobookWebSemanticsAttentionAttention01: rgb(251, 244, 191);
$aviobookWebSemanticsAttentionAttention010: rgb(146, 134, 43);
$aviobookWebSemanticsAttentionAttention02: rgb(250, 241, 172);
$aviobookWebSemanticsAttentionAttention03: rgb(248, 236, 145);
$aviobookWebSemanticsAttentionAttention04: rgb(247, 233, 127);
$aviobookWebSemanticsAttentionAttention05: rgb(246, 228, 98);
$aviobookWebSemanticsAttentionAttention06: rgb(244, 223, 71);
$aviobookWebSemanticsAttentionAttention07: rgb(219, 200, 64);
$aviobookWebSemanticsAttentionAttention08: rgb(208, 190, 60);
$aviobookWebSemanticsAttentionAttention09: rgb(183, 167, 53);
$aviobookWebSemanticsAttentionAttention11: rgb(98, 89, 28);
$aviobookWebSemanticsCautionCaution01: rgb(252, 227, 203);
$aviobookWebSemanticsCautionCaution02: rgb(251, 216, 183);
$aviobookWebSemanticsCautionCaution03: rgb(248, 187, 131);
$aviobookWebSemanticsCautionCaution04: rgb(247, 176, 111);
$aviobookWebSemanticsCautionCaution05: rgb(246, 159, 80);
$aviobookWebSemanticsCautionCaution06: rgb(244, 142, 49);
$aviobookWebSemanticsCautionCaution07: rgb(232, 135, 47);
$aviobookWebSemanticsCautionCaution08: rgb(208, 121, 42);
$aviobookWebSemanticsCautionCaution09: rgb(183, 106, 37);
$aviobookWebSemanticsCautionCaution10: rgb(146, 85, 29);
$aviobookWebSemanticsCautionCaution11: rgb(98, 57, 20);
$aviobookWebSemanticsImportedImported01: rgb(239, 224, 253);
$aviobookWebSemanticsImportedImported02: rgb(235, 215, 252);
$aviobookWebSemanticsImportedImported03: rgb(228, 202, 251);
$aviobookWebSemanticsImportedImported04: rgb(224, 193, 250);
$aviobookWebSemanticsImportedImported05: rgb(217, 179, 249);
$aviobookWebSemanticsImportedImported06: rgb(217, 179, 249);
$aviobookWebSemanticsImportedImported07: rgb(189, 149, 223);
$aviobookWebSemanticsImportedImported08: rgb(179, 141, 211);
$aviobookWebSemanticsImportedImported09: rgb(157, 124, 186);
$aviobookWebSemanticsImportedImported10: rgb(126, 100, 149);
$aviobookWebSemanticsImportedImported11: rgb(84, 66, 99);
$aviobookWebSemanticsSuccessSuccess01: rgb(179, 233, 201);
$aviobookWebSemanticsSuccessSuccess02: rgb(157, 227, 185);
$aviobookWebSemanticsSuccessSuccess03: rgb(124, 218, 162);
$aviobookWebSemanticsSuccessSuccess04: rgb(124, 218, 162);
$aviobookWebSemanticsSuccessSuccess05: rgb(69, 202, 123);
$aviobookWebSemanticsSuccessSuccess06: rgb(37, 193, 100);
$aviobookWebSemanticsSuccessSuccess07: rgb(33, 173, 90);
$aviobookWebSemanticsSuccessSuccess08: rgb(31, 164, 85);
$aviobookWebSemanticsSuccessSuccess09: rgb(28, 145, 75);
$aviobookWebSemanticsSuccessSuccess10: rgb(22, 116, 60);
$aviobookWebSemanticsSuccessSuccess11: rgb(15, 77, 40);
$aviobookWebSemanticsUnclearUnclear01: rgb(206, 253, 255);
$aviobookWebSemanticsUnclearUnclear02: rgb(192, 252, 255);
$aviobookWebSemanticsUnclearUnclear03: rgb(171, 251, 255);
$aviobookWebSemanticsUnclearUnclear04: rgb(157, 251, 255);
$aviobookWebSemanticsUnclearUnclear05: rgb(136, 250, 255);
$aviobookWebSemanticsUnclearUnclear06: rgb(115, 249, 255);
$aviobookWebSemanticsUnclearUnclear07: rgb(103, 224, 229);
$aviobookWebSemanticsUnclearUnclear08: rgb(98, 212, 217);
$aviobookWebSemanticsUnclearUnclear09: rgb(86, 187, 191);
$aviobookWebSemanticsUnclearUnclear10: rgb(69, 149, 153);
$aviobookWebSemanticsUnclearUnclear11: rgb(46, 100, 102);
$aviobookWebSemanticsWarningWarning01: rgb(240, 178, 185);
$aviobookWebSemanticsWarningWarning02: rgb(235, 156, 164);
$aviobookWebSemanticsWarningWarning03: rgb(229, 123, 134);
$aviobookWebSemanticsWarningWarning04: rgb(224, 101, 115);
$aviobookWebSemanticsWarningWarning05: rgb(218, 68, 84);
$aviobookWebSemanticsWarningWarning06: rgb(211, 35, 54);
$aviobookWebSemanticsWarningWarning07: rgb(189, 31, 48);
$aviobookWebSemanticsWarningWarning08: rgb(180, 30, 46);
$aviobookWebSemanticsWarningWarning09: rgb(158, 26, 40);
$aviobookWebSemanticsWarningWarning10: rgb(127, 21, 32);
$aviobookWebSemanticsWarningWarning11: rgb(84, 14, 22);
$colorsAviobookDarkPrimaryAlpha290Fog: rgb(10, 44, 62, 0.9);
$colorsAviobookDarkPrimaryAlpha375Mist: rgb(10, 44, 62, 0.75);
$colorsAviobookDarkPrimaryAlpha450Clouds: rgb(10, 44, 62, 0.5);
$colorsAviobookDarkPrimaryAlpha525Broken: rgb(79,189,230);
$colorsAviobookDarkPrimaryAlpha615Scattered: rgb(10, 44, 62, 0.15);
$colorsAviobookDarkPrimaryAlpha75Skyclear: rgb(10, 44, 62, 0.05);
$colorsAviobookDarkPrimaryBravo290Fog: rgb(15, 56, 77);
$colorsAviobookDarkPrimaryBravo375Mist: rgb(15, 56, 77, 0.75);
$colorsAviobookDarkPrimaryBravo450Clouds: rgb(15,56,77,0.5);
$colorsAviobookDarkPrimaryBravo525Broken: rgb(15, 56, 77, 0.25);
$colorsAviobookDarkPrimaryBravo615Scattered: rgb(15, 56, 77, 0.15);
$colorsAviobookDarkPrimaryBravo75Skyclear: rgb(15, 56, 77, 0.05);
$colorsAviobookDarkPrimaryCharlie290Fog: rgb(17, 64, 88);
$colorsAviobookDarkPrimaryCharlie375Mist: rgb(17, 64, 88, 0.75);
$colorsAviobookDarkPrimaryCharlie450Clouds: rgb(17,64,88,0.5);
$colorsAviobookDarkPrimaryCharlie525Broken: rgb(17, 64, 88, 0.25);
$colorsAviobookDarkPrimaryCharlie615Scattered: rgb(17,64,88,0.15);
$colorsAviobookDarkPrimaryCharlie75Skyclear: rgb(17,64,88,0.05);
$colorsAviobookDarkPrimaryDelta290Fog: rgb(35, 83, 108);
$colorsAviobookDarkPrimaryDelta375Mist: rgb(35, 83, 108, 0.75);
$colorsAviobookDarkPrimaryDelta450Clouds: rgb(35, 83, 108, 0.5);
$colorsAviobookDarkPrimaryDelta525Broken: rgb(35, 83, 108, 0.25);
$colorsAviobookDarkPrimaryDelta615Scattered: rgb(35, 83, 108, 0.15);
$colorsAviobookDarkPrimaryDelta75Skyclear: rgb(35, 83, 108, 0.05);
$colorsAviobookDarkPrimaryEcho290Fog: rgb(55, 98, 120, 0.9);
$colorsAviobookDarkPrimaryEcho375Mist: rgb(55, 98, 120, 0.75);
$colorsAviobookDarkPrimaryEcho450Clouds: rgb(55, 98, 120, 0.5);
$colorsAviobookDarkPrimaryEcho525Broken: rgb(55, 98, 120, 0.25);
$colorsAviobookDarkPrimaryEcho615Scattered: rgb(55, 98, 120, 0.15);
$colorsAviobookDarkPrimaryEcho75Skyclear: rgb(55,98,120, 0.05);
$colorsAviobookDarkSecondaryAlpha290Fog: rgb(40, 73, 90, 0.9);
$colorsAviobookDarkSecondaryAlpha375Mist: rgb(40, 73, 90, 0.75);
$colorsAviobookDarkSecondaryAlpha450Clouds: rgb(40, 73, 90, 0.5);
$colorsAviobookDarkSecondaryAlpha525Broken: rgb(40, 73, 90, 0.25);
$colorsAviobookDarkSecondaryAlpha615Scattered: rgb(40, 73, 90, 0.15);
$colorsAviobookDarkSecondaryAlpha75Skyclear: rgb(40, 73, 90, 0.05);
$colorsAviobookDarkSecondaryBravo290Fog: rgb(0, 132, 200, 0.9);
$colorsAviobookDarkSecondaryBravo375Mist: rgb(0, 132, 200, 0.75);
$colorsAviobookDarkSecondaryBravo450Clouds: rgb(0, 132, 200, 0.5);
$colorsAviobookDarkSecondaryBravo525Broken: rgb(0, 132, 200, 0.25);
$colorsAviobookDarkSecondaryBravo615Scattered: rgb(0, 132, 200, 0.15);
$colorsAviobookDarkSecondaryBravo75Skyclear: rgb(0, 132, 200, 0.05);
$colorsAviobookDarkSecondaryCharlie290Fog: rgb(57, 142, 174, 0.9);
$colorsAviobookDarkSecondaryCharlie375Mist: rgb(57, 142, 174, 0.75);
$colorsAviobookDarkSecondaryCharlie450Clouds: rgb(57, 142, 174, 0.5);
$colorsAviobookDarkSecondaryCharlie525Broken: rgb(57, 142, 174, 0.25);
$colorsAviobookDarkSecondaryCharlie615Scattered: rgb(57, 142, 174, 0.15);
$colorsAviobookDarkSecondaryCharlie75Skyclear: rgb(57, 142, 174, 0.05);
$colorsAviobookDarkSecondaryDelta: rgb(79,189,230);
$colorsAviobookDarkSecondaryDelta290Fog: rgb(79, 189, 230, 0.9);
$colorsAviobookDarkSecondaryDelta375Mist: rgb(79, 189, 230, 0.75);
$colorsAviobookDarkSecondaryDelta450Clouds: rgb(79,189,230,0.5);
$colorsAviobookDarkSecondaryDelta525Broken: rgb(79, 189, 230, 0.25);
$colorsAviobookDarkSecondaryDelta615Scattered: rgb(79, 189, 230, 0.15);
$colorsAviobookDarkSecondaryDelta75Skyclear: rgb(79, 189, 230, 0.05);
$colorsAviobookDarkSecondaryEcho290Fog: rgb(75, 124, 150, 0.9);
$colorsAviobookDarkSecondaryEcho375Mist: rgb(75, 124, 150, 0.75);
$colorsAviobookDarkSecondaryEcho450Clouds: rgb(75, 124, 150, 0.5);
$colorsAviobookDarkSecondaryEcho525Broken: rgb(75, 124, 150, 0.25);
$colorsAviobookDarkSecondaryEcho615Scattered: rgb(75, 124, 150, 0.15);
$colorsAviobookDarkSecondaryEcho75Skyclear: rgb(75, 124, 150, 0.05);
$colorsAviobookDarkSecondaryFoxtrot290Fog: rgb(221,233,240);
$colorsAviobookDarkSecondaryFoxtrot375Mist: rgb(221, 233, 240, 0.75);
$colorsAviobookDarkSecondaryFoxtrot450Clouds: rgb(221, 233, 240, 0.5);
$colorsAviobookDarkSecondaryFoxtrot525Broken: rgb(221, 233, 240, 0.25);
$colorsAviobookDarkSecondaryFoxtrot615Scattered: rgb(221, 233, 240, 0.15);
$colorsAviobookDarkSecondaryFoxtrot75Skyclear: rgb(221, 233, 240, 0.05);
$colorsAviobookDarkSecondaryGolf290Fog: rgb(174, 199, 213, 0.9);
$colorsAviobookDarkSecondaryGolf375Mist: rgb(174, 199, 213, 0.75);
$colorsAviobookDarkSecondaryGolf450Clouds: rgb(174, 199, 213, 0.5);
$colorsAviobookDarkSecondaryGolf525Broken: rgb(174, 199, 213, 0.25);
$colorsAviobookDarkSecondaryGolf615Scattered: rgb(174, 199, 213, 0.15);
$colorsAviobookDarkSecondaryGolf75Skyclear: rgb(174, 199, 213, 0.05);
$colorsAviobookDarkSecondaryHotel290Fog: rgb(20, 71, 97, 0.9);
$colorsAviobookDarkSecondaryHotel375Mist: rgb(20, 71, 97, 0.75);
$colorsAviobookDarkSecondaryHotel450Clouds: rgb(20, 71, 97, 0.5);
$colorsAviobookDarkSecondaryHotel525Broken: rgb(20, 71, 97, 0.25);
$colorsAviobookDarkSecondaryHotel615Scattered: rgb(20, 71, 97, 0.15);
$colorsAviobookDarkSecondaryHotel75Skyclear: rgb(20, 71, 97, 0.05);
$colorsAviobookLightPrimaryCharlie290Fog: rgb(226, 244, 251, 0.9);
$colorsAviobookLightPrimaryCharlie375Mist: rgb(226, 244, 251, 0.75);
$colorsAviobookLightPrimaryCharlie450Clouds: rgb(226, 244, 251, 0.5);
$colorsAviobookLightPrimaryCharlie525Broken: rgb(226, 244, 251, 0.25);
$colorsAviobookLightPrimaryCharlie615Scattered: rgb(226, 244, 251, 0.15);
$colorsAviobookLightPrimaryCharlie75Skyclear: rgb(226, 244, 251, 0.05);
$colorsAviobookLightSecondaryAlpha290Fog: rgb(139, 164, 177, 0.9);
$colorsAviobookLightSecondaryAlpha375Mist: rgb(139, 164, 177, 0.75);
$colorsAviobookLightSecondaryAlpha450Clouds: rgb(139, 164, 177, 0.5);
$colorsAviobookLightSecondaryAlpha525Broken: rgb(139, 164, 177, 0.25);
$colorsAviobookLightSecondaryAlpha615Scattered: rgb(139, 164, 177, 0.15);
$colorsAviobookLightSecondaryAlpha75Skyclear: rgb(139, 164, 177, 0.05);
$colorsAviobookSpecialsFirCpdlcLight03Clouds: rgb(84, 178, 173, 0.5);
$colorsAviobookSpecialsOthersJaipur3LightJaipurLight01Overcast: rgb(218, 101, 169);
$colorsAviobookSpecialsWaypointSelectedPrimaryDark: rgb(255, 156, 217);
$colorsComponentsButtonWebDisabledDisabledFlat: rgb(243, 245, 246);
$colorsNeutralsDark290Fog: rgb(0, 0, 0, 0.9);
$colorsNeutralsDark375Mist: rgb(0, 0, 0, 0.75);
$colorsNeutralsDark450Clouds: rgb(0, 0, 0, 0.5);
$colorsNeutralsDark525Broken: rgb(0, 0, 0, 0.25);
$colorsNeutralsDark615Scattered: rgb(0, 0, 0, 0.15);
$colorsNeutralsDark75Skyclear: rgb(0, 0, 0, 0.05);
$colorsNeutralsGreyDark290Fog: rgb(153, 153, 153, 0.9);
$colorsNeutralsGreyDark375Mist: rgb(153, 153, 153, 0.75);
$colorsNeutralsGreyDark450Clouds: rgb(153, 153, 153, 0.5);
$colorsNeutralsGreyDark525Broken: rgb(153, 153, 153, 0.25);
$colorsNeutralsGreyDark615Scattered: rgb(153, 153, 153, 0.15);
$colorsNeutralsGreyDark75Skyclear: rgb(153, 153, 153, 0.05);
$colorsNeutralsGreyLight290Fog: rgb(211, 211, 211, 0.9);
$colorsNeutralsGreyLight375Mist: rgb(211, 211, 211, 0.75);
$colorsNeutralsGreyLight450Clouds: rgb(211, 211, 211, 0.5);
$colorsNeutralsGreyLight525Broken: rgb(211, 211, 211, 0.25);
$colorsNeutralsGreyLight615Scattered: rgb(211, 211, 211, 0.15);
$colorsNeutralsGreyLight75Skyclear: rgb(211, 211, 211, 0.05);
$colorsNeutralsLight290Fog: rgb(255, 255, 255, 0.9);
$colorsNeutralsLight375Mist: rgb(255,255,255,0.75);
$colorsNeutralsLight450Clouds: rgb(255,255,255,0.5);
$colorsNeutralsLight525Broken: rgb(255, 255, 255, 0.25);
$colorsNeutralsLight615Scattered: rgb(255, 255, 255, 0.15);
$colorsNeutralsLight75Skyclear: rgb(255, 255, 255, 0.05);
$colorsSemanticAttention1Light290Fog: rgb(244, 223, 71, 0.9);
$colorsSemanticAttention1Light375Mist: rgb(244, 223, 71, 0.75);
$colorsSemanticAttention1Light450Clouds: rgb(244,223,71,0.5);
$colorsSemanticAttention1Light525Broken: rgb(244,223,71,0.25);
$colorsSemanticAttention1Light615Scattered: rgb(244,223,71,0.15);
$colorsSemanticAttention1Light75Skyclear: rgb(244,223,71,0.05);
$colorsSemanticAttention2Dark1100Overcast: rgb(204, 188, 71);
$colorsSemanticAttention2Dark290Fog: rgb(204, 188, 71, 0.9);
$colorsSemanticAttention2Dark375Mist: rgb(204, 188, 71, 0.75);
$colorsSemanticAttention2Dark450Clouds: rgb(204, 188, 71, 0.5);
$colorsSemanticAttention2Dark525Broken: rgb(204, 188, 71, 0.25);
$colorsSemanticAttention2Dark615Scattered: rgb(204, 188, 71, 0.15);
$colorsSemanticAttention2Dark75Skyclear: rgb(204, 188, 71, 0.05);
$colorsSemanticCaution1Light290Fog: rgb(244, 142, 49, 0.9);
$colorsSemanticCaution1Light375Mist: rgb(244, 142, 49, 0.75);
$colorsSemanticCaution1Light450Clouds: rgb(244, 142, 49, 0.5);
$colorsSemanticCaution1Light525Broken: rgb(244, 142, 49, 0.25);
$colorsSemanticCaution1Light615Scattered: rgb(244, 142, 49, 0.15);
$colorsSemanticCaution1Light75Skyclear: rgb(244, 142, 49, 0.05);
$colorsSemanticCaution2Dark1100Overcast: rgb(195, 91, 37);
$colorsSemanticCaution2Dark290Fog: rgb(195, 91, 37, 0.9);
$colorsSemanticCaution2Dark375Mist: rgb(195, 91, 37, 0.75);
$colorsSemanticCaution2Dark450Clouds: rgb(195, 91, 37, 0.5);
$colorsSemanticCaution2Dark525Broken: rgb(195, 91, 37, 0.25);
$colorsSemanticCaution2Dark615Scattered: rgb(195, 91, 37, 0.15);
$colorsSemanticCaution2Dark75Skyclear: rgb(195, 91, 37, 0.05);
$colorsSemanticImported1Light290Fog: rgb(210, 166, 248, 0.9);
$colorsSemanticImported1Light375Mist: rgb(210, 166, 248, 0.75);
$colorsSemanticImported1Light450Clouds: rgb(210, 166, 248, 0.5);
$colorsSemanticImported1Light525Broken: rgb(210, 166, 248, 0.25);
$colorsSemanticImported1Light615Scattered: rgb(210, 166, 248, 0.15);
$colorsSemanticImported1Light75Skyclear: rgb(210, 166, 248, 0.05);
$colorsSemanticImported2Dark1100Overcast: #8e4dc5;
$colorsSemanticImported2Dark290Fog: rgb(142, 77, 197, 0.9);
$colorsSemanticImported2Dark375Mist: rgb(142, 77, 197, 0.75);
$colorsSemanticImported2Dark450Clouds: rgb(142, 77, 197, 0.5);
$colorsSemanticImported2Dark525Broken: rgb(142, 77, 197, 0.25);
$colorsSemanticImported2Dark615Scattered: rgb(142, 77, 197, 0.15);
$colorsSemanticImported2Dark75Skyclear: rgb(142, 77, 197, 0.05);
$colorsSemanticSuccess1Light290Fog: rgb(37,193,100);
$colorsSemanticSuccess1Light375Mist: rgb(37,193,100,0.75);
$colorsSemanticSuccess1Light450Clouds: rgb(37,193,100,0.5);
$colorsSemanticSuccess1Light525Broken: rgb(37,193,100,0.25);
$colorsSemanticSuccess1Light615Scattered: rgb(37,193,100,0.15);
$colorsSemanticSuccess1Light75Skyclear: rgb(37,193,100,0.05);
$colorsSemanticSuccess2Dark1100Overcast: #19701f;
$colorsSemanticSuccess2Dark290Fog: rgb(25,112,31,0.9);
$colorsSemanticSuccess2Dark375Mist: rgb(25,112,31,0.75);
$colorsSemanticSuccess2Dark450Clouds: rgb(25,112,31,0.5);
$colorsSemanticSuccess2Dark525Broken: rgb(25,112,31,0.25);
$colorsSemanticSuccess2Dark615Scattered: rgb(25,112,31,0.15);
$colorsSemanticSuccess2Dark75Skyclear: rgb(25, 112, 31, 0.05);
$colorsSemanticUnclear1Light290Fog: rgb(115, 249, 255, 0.9);
$colorsSemanticUnclear1Light375Mist: rgb(115, 249, 255, 0.75);
$colorsSemanticUnclear1Light450Clouds: rgb(115, 249, 255, 0.5);
$colorsSemanticUnclear1Light525Broken: rgb(115, 249, 255, 0.25);
$colorsSemanticUnclear1Light615Scattered: rgb(115, 249, 255, 0.15);
$colorsSemanticUnclear1Light75Skyclear: rgb(115, 249, 255, 0.05);
$colorsSemanticUnclear2Dark1100Overcast: rgb(79, 206, 209);
$colorsSemanticUnclear2Dark290Fog: rgb(79, 206, 209, 0.9);
$colorsSemanticUnclear2Dark375Mist: rgb(79, 206, 209, 0.75);
$colorsSemanticUnclear2Dark450Clouds: rgb(79, 206, 209, 0.5);
$colorsSemanticUnclear2Dark525Broken: rgb(79, 206, 209, 0.25);
$colorsSemanticUnclear2Dark615Scattered: rgb(79, 206, 209, 0.15);
$colorsSemanticUnclear2Dark75Skyclear: rgb(79, 206, 209, 0.05);
$colorsSemanticWarning1Light290Fog: rgb(211, 35, 54, 0.9);
$colorsSemanticWarning1Light375Mist: rgb(211, 35, 54, 0.75);
$colorsSemanticWarning1Light450Clouds: rgb(211, 35, 54, 0.5);
$colorsSemanticWarning1Light525Broken: rgb(211, 35, 54, 0.25);
$colorsSemanticWarning1Light615Scattered: rgb(211, 35, 54, 0.15);
$colorsSemanticWarning1Light75Skyclear: rgb(211, 35, 54, 0.05);
$colorsSemanticWarning2Dark1100Overcast: rgb(164, 56, 57);
$colorsSemanticWarning2Dark290Fog: rgb(164, 56, 57, 0.9);
$colorsSemanticWarning2Dark375Mist: rgb(164, 56, 57, 0.75);
$colorsSemanticWarning2Dark450Clouds: rgb(164, 56, 57, 0.5);
$colorsSemanticWarning2Dark525Broken: rgb(164, 56, 57, 0.25);
$colorsSemanticWarning2Dark615Scattered: rgb(164, 56, 57, 0.15);
$colorsSemanticWarning2Dark75Skyclear: rgb(164, 56, 57, 0.05);
$colorsSpecials011Light290Fog: rgb(218, 101, 169, 0.9);
$colorsSpecials011Light375Mist: rgb(218, 101, 169, 0.75);
$colorsSpecials011Light450Clouds: rgb(218, 101, 169, 0.5);
$colorsSpecials011Light615Scattered: rgb(218, 101, 169, 0.15);
$colorsSpecials011Light75Skyclear: rgb(218, 101, 169, 0.05);
$colorsSpecials012Dark290Fog: rgb(159, 47, 112, 0.9);
$colorsSpecials012Dark375Mist: rgb(159, 47, 112, 0.75);
$colorsSpecials012Dark450Clouds: rgb(159, 47, 112, 0.5);
$colorsSpecials012Dark525Broken: rgb(159, 47, 112, 0.25);
$colorsSpecials012Dark615Scattered: rgb(159, 47, 112, 0.15);
$colorsSpecials012Dark75Skyclear: rgb(159, 47, 112, 0.05);
$colorsSpecials021Light290Fog: rgb(236, 211, 248, 0.9);
$colorsSpecials021Light375Mist: rgb(236, 211, 248, 0.75);
$colorsSpecials021Light450Clouds: rgb(236, 211, 248, 0.5);
$colorsSpecials021Light525Broken: rgb(236, 211, 248, 0.25);
$colorsSpecials021Light615Scattered: rgb(236, 211, 248, 0.15);
$colorsSpecials021Light75Skyclear: rgb(236, 211, 248, 0.05);
$colorsSpecials022Dark290Fog: rgb(116, 51, 172, 0.9);
$colorsSpecials022Dark375Mist: rgb(116, 51, 172, 0.75);
$colorsSpecials022Dark450Clouds: rgb(116, 51, 172, 0.5);
$colorsSpecials022Dark525Broken: rgb(116, 51, 172, 0.25);
$colorsSpecials022Dark615Scattered: rgb(116, 51, 172, 0.15);
$colorsSpecials022Dark75Skyclear: rgb(116, 51, 172, 0.05);
$colorsSpecials02Chicago3Medium290Fog: rgb(169, 67, 255, 0.9);
$colorsSpecials02Chicago3Medium375Mist: rgb(169, 67, 255, 0.75);
$colorsSpecials02Chicago3Medium450Clouds: rgb(169, 67, 255, 0.5);
$colorsSpecials02Chicago3Medium525Broken: rgb(169, 67, 255, 0.25);
$colorsSpecials02Chicago3Medium615Scattered: rgb(169, 67, 255, 0.15);
$colorsSpecials031Light290Fog: rgb(55, 194, 149, 0.9);
$colorsSpecials031Light375Mist: rgb(55, 194, 149, 0.75);
$colorsSpecials031Light450Clouds: rgb(55, 194, 149, 0.5);
$colorsSpecials031Light525Broken: rgb(55, 194, 149, 0.25);
$colorsSpecials031Light615Scattered: rgb(55, 194, 149, 0.15);
$colorsSpecials031Light75Skyclear: rgb(55, 194, 149, 0.05);
$colorsSpecials032Dark290Fog: rgb(16, 146, 106, 0.9);
$colorsSpecials032Dark375Mist: rgb(16, 146, 106, 0.75);
$colorsSpecials032Dark450Clouds: rgb(16, 146, 106, 0.5);
$colorsSpecials032Dark525Broken: rgb(16, 146, 106, 0.25);
$colorsSpecials032Dark615Scattered: rgb(16, 146, 106, 0.15);
$colorsSpecials032Dark75Skyclear: rgb(16, 146, 106, 0.05);
$colorsSpecials041Light290Fog: rgb(221, 181, 118, 0.9);
$colorsSpecials041Light375Mist: rgb(221, 181, 118, 0.75);
$colorsSpecials041Light450Clouds: rgb(221, 181, 118, 0.5);
$colorsSpecials041Light525Broken: rgb(221, 181, 118, 0.25);
$colorsSpecials041Light615Scattered: rgb(221, 181, 118, 0.15);
$colorsSpecials041Light75Skyclear: rgb(221, 181, 118, 0.05);
$colorsSpecials042Dark290Fog: rgb(193, 121, 18, 0.9);
$colorsSpecials042Dark375Mist: rgb(193, 121, 18, 0.75);
$colorsSpecials042Dark450Clouds: rgb(193, 121, 18, 0.5);
$colorsSpecials042Dark525Broken: rgb(193, 121, 18, 0.25);
$colorsSpecials042Dark615Scattered: rgb(193, 121, 18, 0.15);
$colorsSpecials042Dark75Skyclear: rgb(193, 121, 18, 0.05);
$colorsSpecialsGlobeBothAirwayAirwaySelection: rgb(255, 136, 108, 0.5);
$colorsSpecialsGlobeDarkMapTeal125: rgb(32, 132, 123, 0.25);
$colorsSpecialsGlobeDarkMapTeal150: rgb(32, 132, 123, 0.5);
$colorsSpecialsGlobeDarkMapTeal175: rgb(32, 132, 123, 0.75);
$colorsSpecialsGlobeLightMapTeal225: rgb(84, 178, 173, 0.25);
$colorsSpecialsGlobeLightMapTeal275: rgb(84, 178, 173, 0.75);
$coreAttention: #f4df47;
$coreCaution: #f48e31;
$coreCautionDark: #92551D;
$coreImported: #d2a6f8;
$coreSuccess: #25c164;
$coreWarning: #d32336;
$coreWhite100: #ffffff;
$darkPrimaryAlpha: #0a2c3e;
$darkPrimaryBravo: #0f384d;
$darkPrimaryCharlie: #114058;
$darkPrimaryDelta: #23536c;
$darkPrimaryEcho: #376278;
$darkSecondaryDelta: #4fbde6;
$inactiveColor: rgb(48,209,88);
$iosBlue: rgb(0,122,255);
$iosSystemAccentsSystemRedLight: rgb(255, 59, 48);
$iosSystemFillsDark2SecondaryFillColor: rgb(120, 120, 128, 0.32);
$iosSystemFillsDark3TertiaryFillColor: rgb(118, 118, 128, 0.24);
$iosSystemFillsLight2SecondaryFillColor: rgb(120, 120, 128, 0.16);
$iosSystemFillsLight3TertiaryFillColor: rgb(118, 118, 128, 0.12);
$iosSystemLabelsDark2SecondaryLabelColor: rgb(235, 235, 245, 0.6);
$iosSystemSeparatorsDarkOpaqueSeparatorColor: rgb(56, 56, 58);
$iosSystemSeparatorsDarkSeparatorColor: rgb(84, 84, 88, 0.65);
$iosSystemSeparatorsLightSeparatorColor: rgb(60, 60, 67, 0.36);
$lightPrimaryAlpha: rgb(20, 71, 97);
$lightPrimaryEcho: #999999;
$mapsAmsterdamAmsterdam04: rgb(255, 97, 114);
$mapsAmsterdamAmsterdam05: rgb(255, 72, 72);
$mapsAmsterdamAmsterdam06: rgb(213, 67, 80);
$mapsAmsterdamAmsterdam09: rgb(133, 0, 12);
$mapsAmsterdamAmsterdam10: rgb(94, 0, 0);
$mapsCairoCairo01: rgb(245, 224, 186);
$mapsCairoCairo02: rgb(243, 218, 178);
$mapsCairoCairo03: rgb(236, 206, 147);
$mapsCairoCairo04: rgb(226, 187, 126);
$mapsCairoCairo05: rgb(221, 181, 118);
$mapsCairoCairo06: rgb(216, 171, 107);
$mapsCairoCairo07: rgb(195, 133, 63);
$mapsCairoCairo08: rgb(193, 121, 18);
$mapsCairoCairo09: rgb(179, 118, 56);
$mapsCairoCairo10: rgb(165, 106, 54);
$mapsCairoCairo11: rgb(136, 76, 44);
$mapsCapetownCapetown04: rgb(255, 193, 43);
$mapsCapetownCapetown05: rgb(244, 175, 9);
$mapsCapetownCapetown06: rgb(200, 142, 0);
$mapsCapetownCapetown07: rgb(157, 111, 0);
$mapsHawaiiHawaii01: rgb(219, 236, 230);
$mapsHawaiiHawaii02: rgb(204, 232, 222);
$mapsHawaiiHawaii03: rgb(164, 222, 201);
$mapsHawaiiHawaii04: rgb(122, 211, 179);
$mapsHawaiiHawaii05: rgb(98, 205, 167);
$mapsHawaiiHawaii06: rgb(55, 194, 149);
$mapsHawaiiHawaii08: rgb(16, 146, 106);
$mapsJaipurJaipur04: rgb(218, 101, 169);
$mapsJaipurJaipur04Alpha50: rgb(218, 101, 169, 0.5);
$mapsJaipurJaipur08: rgb(159, 47, 112);
$mapsNewyorkNewyork05: rgb(255, 136, 108);
$mapsOdesaOdesa04: rgb(255, 244, 94);
$mapsOdesaOdesa05: rgb(241, 212, 12);
$mapsOdesaOdesa06: rgb(204, 170, 0);
$mapsOdesaOdesa07: rgb(175, 146, 2);
$mapsProvenceProvence03: rgb(225, 189, 255);
$mapsProvenceProvence06: rgb(169, 67, 255);
$mapsProvenceProvence09: rgb(99, 43, 146);
$mapsRioRio05: rgb(84, 178, 173);
$mapsRioRio08: rgb(32, 132, 123);
$neutralsDark: #000000;
$neutralsGreyDark: #999999;
$neutralsGreyLight: #d3d3d3;
$neutralsLight: #ffffff;
$semanticAttention: #f4df47;
$semanticCaution: #f48e31;
$semanticImported: #d2a6f8;
$semanticSuccess: #25c164;
$semanticUnclear: #47aacf;
$semanticWarning: #d32336;
$systemblueDark: rgb(10, 132, 255);
$systemblueLight: rgb(0, 122, 255);
$systemredDark: rgb(255, 69, 58);