@use 'styles/constants.scss';
@use 'styles/colors.scss';

.global-message-container {
  margin-bottom: constants.$l;

  &.next-message-by-same-user {
    margin-bottom: constants.$s;
  }
}

.pending-message-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: flex-end;

  > .pending-message-content-container {
    display: flex;
    justify-content: flex-end;

    > .icon {
      align-self: center;
      margin-left: 0.5rem;
    }
  }

  > .pending-message-retry-text {
    margin-right: 4rem;

    > button {
      background-color: transparent;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
      text-decoration: underline;
      text-decoration-color: colors.$aviobookWebSemanticsCautionCaution10;
    }
  }
}
