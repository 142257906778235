@use 'styles/colors';
@use '../../_styles/breakpoints';

$max-height: calc(100vh - 10rem);
$padding: 1.5rem;

.modal-dimmer {
  border: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;

  @include breakpoints.breakpoint(mobile) {
    display: none;
  }
}

.modal {
  border-radius: 0.4rem;
  z-index: 10000;
  width: 60rem;
  max-height: $max-height;
  overflow-y: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoints.breakpoint(mobile) {
    width: 100%;
    height: 100%;
    max-height: unset;
  }

  .modal-close-icon {
    position: absolute;
    right: 3rem;
    top: 3rem;
    margin: 0;

    @include breakpoints.breakpoint(mobile) {
      right: $padding;
      top: $padding;
    }
  }

  .modal-box {
    background-color: colors.$coreWhite100;
    width: 100%;
    height: 100%;
    max-height: $max-height;

    @include breakpoints.breakpoint(mobile) {
      max-height: unset;
    }

    form {
      max-height: calc(#{$max-height} - 2 * #{$padding});

      @include breakpoints.breakpoint(mobile) {
        max-height: unset;
      }
    }

    &,
    form {
      display: flex;
      flex-direction: column;

      > :not(:first-child) {
        margin-top: 2.5rem;
      }
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0;
      padding: $padding;
      border-bottom: 0.1rem solid colors.$aviobookWebPrimaryZulu03;
      h1 {
        text-transform: uppercase;
        font-size: 1.6rem;
        color: colors.$aviobookDarkSecondaryHotel;
        font-weight: bold;
        @include breakpoints.breakpoint(mobile) {
          font-size: 2rem;
        }
      }
    }

    .modal-content {
      display: flex;
      justify-content: center;
      flex: 1;
      overflow-y: auto;
      margin-right: calc(#{$padding} - 1rem);
      padding: $padding * 2;
      color: colors.$aviobookDarkSecondaryHotel;
      > :not(:first-child) {
        margin-top: 1.5rem;
      }
    }

    .modal-buttons {
      border-top: 0.1rem solid colors.$aviobookWebPrimaryZulu03;
      padding: $padding;
      flex: 0;
      display: flex;
      justify-content: flex-end;
      padding-right: $padding;
      > :not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }
}
