@use '../../../../_styles/colors';
@use '../../../../_styles/constants';

.checkbox-wrapper {
  &.is-disabled {
    opacity: constants.$disabled-opacity;
  }

  > div {
    color: colors.$input-border-color;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.is-checked {
      color: colors.$primary-color;
    }

    &:hover {
      color: colors.$primary-color;
    }

    > label {
      color: colors.$text-color;
      margin-left: 1.5rem;

      &.is-error {
        color: colors.$red;
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 5rem;
      height: 2.4rem;

      .toggle {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: colors.$primary-color;
        }

        &:checked .toggle:focus + .slider {
          box-shadow: 0 0 0.1rem colors.$primary-color;
        }

        &:checked + .slider:before {
          transform: translateX(2.6rem);
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: colors.$grey-light;
        transition: 0.4s;
        border-radius: 3.4rem;

        &:before {
          position: absolute;
          content: '';
          height: 1.6rem;
          width: 1.6rem;
          left: 0.4rem;
          bottom: 0.4rem;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }
  }
}
