@use 'styles/colors';

.add {
  right: 1rem;
}

.chatbots-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }
}

.center {
  text-align: center;
}
