@use 'styles/colors.scss';
@use 'styles/constants.scss';

.flightroom-info-item-container {
  display: flex;
  align-items: center;
  background-color: colors.$aviobookWebSecondaryVictorVictor02;
  padding: constants.$l;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 0.5rem; /* Adjust the margin value as needed */
  }

  .flightroom-info-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flightroom-info-icon {
    margin-right: constants.$l;
  }
}
