@use 'styles/colors';
@use 'styles/constants';

.buttons-left-container {
  display: flex;
  flex: 1;
}

.flightroom-request-modal-center {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  overflow-y: auto;

  .flightroom-request-modal-select-all {
    cursor: pointer;
    margin-bottom: 2rem;
  }

  .flightroom-request-modal-content {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .flightroom-request-checkbox {
    margin-right: constants.$m;
  }
}
