@use 'styles/colors';
@use 'styles/constants';

.add {
  right: 1rem;
}

.role-form-top-container {
  display: flex;
  justify-content: flex-end;
}

.role-form-checkbox-airline-role {
  display: flex;
  align-items: center;
  padding-top: constants.$xl;
}
