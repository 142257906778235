@use 'styles/colors.scss';
@use 'styles/constants.scss';

.notification-center-container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .notification-center-top-container {
    display: flex;
    flex-direction: row;
    background-color: colors.$coreWhite100;
    padding: constants.$m constants.$xl;
    height: 4.6rem;
    align-items: center;
    border-bottom: 0.1rem solid colors.$aviobookLightLegacyPrimaryBravo;

    .notification-center-top-header-left {
      display: flex;
      flex: 1;
    }
    .notification-center-top-header-middle {
      display: flex;
      flex: 1;
    }
    .notification-center-top-header-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  .notification-center-content-container {
    overflow-y: auto;

    &.is-empty-notification-center {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      .notifications-center-empty-notifications {
        margin-top: 2rem;
      }
    }

    &.is-loading-notification-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}
