@use 'styles/colors.scss';
@use 'styles/constants.scss';

.tab-bar {
  .tab-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;

    .tab-item {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding: 10px;
      border-bottom: 2px solid transparent;
      transition: border-color 0.3s ease-in-out;

      &.active {
        color: colors.$aviobookWebAccentRomeo07;
        border-color: colors.$aviobookWebAccentRomeo07;
      }
    }
  }
}
