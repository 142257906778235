@use 'styles/colors';
@use 'styles/constants';

.content-container {
  .content {
    display: flex;
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    max-width: 36rem;
    flex-direction: column;
    align-items: center;
  }

  .description {
    margin-top: constants.$l;
    margin-bottom: constants.$xl;
    text-align: center;
  }
}
