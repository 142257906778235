@use 'styles/constants';

$font-size: 1.6rem;
$font-family: 'Open Sans', sans-serif;

$border-radius: 0;
$disabled-opacity: 0.75;
$navbar-height: 6.5rem;
$input-height: 3.5rem;

$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;
