@use 'styles/colors';
@use 'styles/constants';

.disabled-link {
  pointer-events: none;
}

.bread-crumb {
  display: flex;
  flex-direction: column;
  background-color: colors.$coreWhite100;
  padding: constants.$l constants.$l;

  .nav-bar-ul {
    display: inline-flex;

    .nav-bar-li {
      * {
        text-transform: uppercase;
        cursor: pointer;
      }

      &:not(:last-child)::after {
        content: '/';
        margin: 0 constants.$m;
        font-size: 2rem;
      }
    }
  }
}
