@use 'styles/colors.scss';
@use 'styles/constants.scss';

.flightroom-search-item-container {
  all: unset;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: constants.$m 1.2rem;
  background-color: colors.$coreWhite100;
  align-items: center;
  border-top: 0.1rem solid colors.$aviobookWebSecondaryVictorVictor02;
  border-bottom: 0.1rem solid colors.$aviobookWebPrimaryZulu01;

  &.alternative-color {
    background-color: colors.$aviobookWebSecondaryVictorVictor02;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .flightroom-search-search-item-left {
    display: flex;
    flex: 1;
  }

  .flightroom-search-search-item-center {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .flightroom-search-search-item-center-airports {
      display: flex;
      flex-direction: row;
      align-items: center;

      > :first-child {
        margin-right: constants.$m;
      }

      > :last-child {
        margin-left: constants.$m;
      }
    }
  }

  .flightroom-search-search-item-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
