@use 'styles/colors.scss';
@use 'styles/constants.scss';

.empty-container {
  display: flex;
  flex: 1;
  justify-content: center;

  &.active {
    background-color: colors.$aviobookLightLegacyPrimaryBravo;
  }

  &.completed {
    background-color: colors.$aviobookWebNeutralsLightLight03;
  }

  .empty-content-container {
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 36rem;
    text-align: center;

    .empty-title {
      margin-top: 0.4rem;
    }

    .empty-body-text {
      margin-top: constants.$m;
    }
  }
}
