@use 'styles/colors.scss';
@use 'styles/constants.scss';

.flightrooms-container {
  height: 100vh;
  display: flex;
  background-color: colors.$coreWhite100;
  flex-direction: column;
  width: 40rem;

  .flightrooms-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .flightrooms-header-top-container {
      display: flex;
      position: relative;
      height: 4.5rem;
      padding: 0 2rem;

      .flightrooms-header-top-left {
        // This balances out the header so that the statusPill is in the correct position
        width: 65px;
      }

      .flightrooms-header-top-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1; // Take remaining space
      }

      .flightrooms-header-top-right {
        display: flex;
        align-items: center;

        > .flightrooms-header-notification-icon {
          background-color: transparent;
          margin-right: constants.$m;
          position: relative;

          > .flightrooms-header-notification-indicator {
            position: absolute;
            top: 0.2rem;
            margin-left: 1.2rem;
            background-color: colors.$coreWarning;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
          }
        }
      }
    }

    .flightrooms-header-search {
      background-color: colors.$aviobookWebSecondaryVictorVictor03;
      padding: constants.$m 2rem;

      .flightrooms-search {
        width: 100%;
      }
    }

    .flightrooms-header-nav-container {
      border-bottom: 0.1rem solid colors.$aviobookWebPrimaryZulu01;

      .flightrooms-header-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style: none;
        height: 4.3rem;
        border-bottom: 0.1rem solid colors.$aviobookLightPrimaryBravo;
        padding: 1.4rem;

        .flightrooms-header-nav-item {
          padding: constants.$l 4.5rem;

          > button &:focus {
            width: 100%;
            border-bottom: 0.2rem solid colors.$aviobookWebAccentRomeo07;
          }
        }
      }
    }
  }

  .flightrooms-content-container {
    overflow-y: auto;

    .flightrooms-content-fetching-spinner {
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    &.flightrooms-content-container-loader {
      display: flex;
      flex: 1;
      justify-content: center;
      align-content: center;
    }

    &.is-empty-search {
      display: flex;
      justify-content: center;
      align-items: center;

      .flightrooms-empty-search-text {
        margin-top: 2rem;
        padding: 0 2rem;
        text-align: center;
      }
    }

    &:not(:only-child) {
      visibility: visible;
    }

    // Show the sidebar when the list overflows
    &:only-child {
      visibility: hidden;
    }

    &.is-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}
