@use 'styles/colors';

.authorized-layout {
  display: flex;
  flex-direction: column;

  /* Set properties for screens */
  & > :last-child {
    background-color: colors.$aviobookWebSecondaryVictorVictor07;
    padding: 1.5rem;
  }
}
