@use 'styles/colors';
@use 'styles/constants';
@use '../text/text';

@mixin setColorTo($color) {
  color: $color;
  &:hover,
  &:focus {
    color: darkenOnHover($color);
  }
}

@mixin setBackgroundColorTo($color) {
  background-color: $color;
  &:hover,
  &:focus {
    background-color: darkenOnHover($color, 8%);
  }
}

$padding: 1.1rem 2.4rem;

button {
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
}

@mixin buttonStyle($backgroundColor, $textColor, $borderColor) {
  height: 3.5rem;
  padding: 0.5rem;
  color: $textColor;
  font-size: 1.4rem;
  font-weight: bold;
  @include setBackgroundColorTo($backgroundColor);

  &.outline {
    background-color: transparent;
    border: 0.1rem solid $borderColor;
    @include setBackgroundColorTo($backgroundColor);

    &.disabled {
      border-color: colors.$aviobookWebPrimaryZulu02;
      color: colors.$aviobookWebPrimaryZulu04;
    }

    &.loading {
      pointer-events: none;
      color: transparent;
      &:hover,
      &:focus {
        color: transparent;
      }

      .spinner-wrapper {
        margin: 0;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.buttonCMS {
  &.transparent {
    @include buttonStyle(transparent, colors.$aviobookLightPrimaryAlpha, colors.$aviobookLightPrimaryAlpha);
  }

  &.primary {
    @include buttonStyle(colors.$semanticUnclear, colors.$coreWhite100, colors.$aviobookDarkSecondaryDelta);
  }

  &.login {
    @include buttonStyle(colors.$semanticUnclear, colors.$coreWhite100, colors.$aviobookDarkSecondaryDelta);
  }

  &.secondary {
    @include buttonStyle(colors.$aviobookCMSDisabledButton, colors.$aviobookCMSInfoText, colors.$aviobookLightPrimaryAlpha);
  }

  &.delete {
    @include buttonStyle(colors.$coreWhite100, colors.$aviobookDarkLegacySemanticWarning, colors.$aviobookDarkLegacySemanticWarning);
  }

  &.neutral {
    @include buttonStyle(colors.$aviobookLightPrimaryBravo, colors.$aviobookLightPrimaryAlpha, colors.$aviobookLightPrimaryAlpha);
  }

  &.negative {
    @include buttonStyle(
      colors.$aviobookDarkLegacySemanticWarning,
      colors.$coreWhite100,
      colors.$aviobookDarkLegacySemanticWarning
    );
  }

  &.dark {
    @include buttonStyle(colors.$aviobookLightPrimaryAlpha, colors.$coreWhite100, colors.$aviobookLightPrimaryAlpha);
  }

  &.disabled {
    color: colors.$aviobookWebPrimaryZulu04;
    background-color: colors.$aviobookCMSDisabledButton;
  }
}

.as-text {
  background: transparent;
  &.neutral {
    @include setColorTo(colors.$aviobookWebNeutralsDarkDark01);
  }
  &.primary {
    @include setColorTo(colors.$aviobookDarkSecondaryDelta);
  }
  &.negative {
    @include setColorTo(colors.$aviobookDarkLegacySemanticWarning);
  }
}

@mixin asTextButtonStyle($padding) {
  padding: $padding;
}

.buttonCMS,
.as-text {
  border-radius: 0.4rem;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > :not(:first-child) {
    margin-left: 0.8rem;
  }
  &.loading {
    pointer-events: none;
    color: transparent;
    &:hover,
    &:focus {
      color: transparent;
    }

    .spinner-wrapper {
      margin: 0;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
