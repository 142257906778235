@use 'styles/colors.scss';
@use 'styles/constants.scss';

.chat-input-toolbar-container {
  background-color: colors.$aviobookDarkNeutralsLight;
  padding: constants.$m;

  > .input-container {
    flex: 1;
    display: flex;

    > .input-field {
      flex: 1;
      margin-right: constants.$m;

      > .input-icon {
        top: calc(50% - 0.2rem);
      }

      > .input {
        border: none;
        background-color: colors.$aviobookWebSecondaryVictorVictor03;
        border-bottom: 0.2rem solid colors.$lightPrimaryAlpha;
        height: 5rem;

        &:focus {
          // TODO: focus style?
          border-bottom-width: 0.3rem;
        }
      }
    }

    .send-button {
      width: 5rem;
      height: 5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      > icon,
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
