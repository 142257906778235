@use 'styles/colors.scss';
@use 'styles/constants.scss';

.unread-dot-container {
  align-self: center;
  background-color: colors.$coreAttention;
  border-radius: constants.$m;
  display: flex;
  justify-content: center;
  padding: 0 0.6rem;
  margin-right: constants.$s;

  &.small {
    width: 1.8rem;
    height: 1.8rem;
  }

  &.medium {
    width: 2rem;
    height: 2rem;
  }

  &.large {
    width: constants.$xl;
    height: constants.$xl;
  }
}
