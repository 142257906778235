@use 'styles/colors.scss';
@use 'styles/constants.scss';

.profile-container {
  height: 100vh;
  display: flex;
  background-color: colors.$coreWhite100;
  flex-direction: column;
  width: 40rem;

  .profile-section-top-container {
    background-color: colors.$aviobookWebSecondaryVictorVictor02;
    padding: constants.$m constants.$l;

    .profile-section-top-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // TODO: remove this when we add back settings icon
      .profile-section-top-header-left-spacer {
        height: 2rem;
        width: 2rem;
      }
    }

    .profile-section-top-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile-icon-container {
        padding: 1.8rem;
        border-radius: 10rem;
        background-color: colors.$aviobookDarkSecondaryHotel;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: constants.$l;
      }
      > *:nth-child(2) {
        margin-bottom: constants.$s;
      }
    }
  }
  .profile-mid-bot-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .profile-section-mid-container {
      .profile-section-mid-list-item {
        display: flex;
        align-items: center;
        padding: constants.$xl;
        border-bottom: 0.1rem solid colors.$aviobookWebPrimaryZulu01;

        .profile-section-mid-list-item-icon {
          margin-right: constants.$xl;
        }
      }
    }

    .profile-section-bot-container {
      display: flex;
      flex-direction: column;
      padding: constants.$xl;
      justify-content: space-between;
      align-items: center;

      > button {
        width: 100%;
      }
    }

    .profile-section-bot-container Button:not(:last-child) {
      margin-bottom: constants.$m;
    }
  }
}
