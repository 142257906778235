@import '../../../_styles/colors';
@import '../../../_styles/constants';

.input-wrapper {
  position: relative;

  .input-wrapper-label:not(:last-child) {
    display: flex;
    align-items: center;
    height: $input-icon-height;
    margin-bottom: $input-label-bottom-margin;
    font-size: 1.4rem;
    color: $grey-dark;

    > :not(:first-child):not(sup) {
      margin-left: 0.75rem;
    }

    > sup {
      font-size: .9em;
      margin-left: 0.3rem;
    }

    i,
    svg {
      width: $input-icon-height;
      height: $input-icon-height;
    }
  }
}

.ui.input,
.ui.selection.dropdown {
  min-width: 100%;
}
