@use 'styles/colors.scss';
@use 'styles/constants.scss';

.chat-container {
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  background-color: colors.$aviobookLightPrimaryBravo;

  > .spinner-wrapper {
    flex: 1;
  }

  .chat-load-more-button {
    background-color: #486f84;
    padding: constants.$s constants.$m;
    border-radius: 0.4rem;
    margin: constants.$l auto;
  }
}

// gifted-chat styles fixes
.chat-container {
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    flex: 1;
  }

  > div > div > div > div > div > div > div > div > div {
    width: 90%;
    margin: 0 auto;
    max-width: 96rem;
  }
}
