@use 'styles/colors';
@use 'styles/constants';

.system-message-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: constants.$l;
  padding: constants.$l constants.$m;
  width: 100%;
  color: colors.$neutralsLight;
  background-color: colors.$aviobookDarkSecondaryHotel;

  &.ATTENTION {
    color: colors.$aviobookWebSemanticsAttentionAttention11;
    background-color: colors.$semanticAttention;
  }

  &.CAUTION {
    background-color: colors.$semanticCaution;
  }

  &.IMPORTED {
    color: colors.$aviobookWebSemanticsAttentionAttention11;
    background-color: colors.$semanticImported;
  }

  &.OK {
    background-color: colors.$semanticSuccess;
  }

  &.UNCLEAR {
    background-color: colors.$semanticUnclear;
  }

  &.WARNING {
    background-color: colors.$semanticWarning;
  }

  &.UNKNOWN {
    background-color: colors.$aviobookDarkSecondaryHotel;
  }

  .system-message {
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: row;

    .system-message-icon {
      margin-right: constants.$m;
    }

    .system-message-text {
      flex: 1;
      margin-right: constants.$s;
    }
  }

  .system-message-time {
    opacity: 0.7;
  }
}
