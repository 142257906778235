.text {
  &.wrap {
    white-space: pre-wrap;
  }

  &.XXS {
    font-size: 1.1rem;
  }

  &.XS {
    font-size: 1.2rem;
  }

  &.SM {
    font-size: 1.4rem;
  }

  &.MD {
    font-size: 1.6rem;
  }

  &.LG {
    font-size: 1.8rem;
  }

  &.XL {
    font-size: 2.4rem;
  }

  &.XXL {
    font-size: 3.4rem;
  }

  &.regular {
    font-weight: normal;
  }

  &.semiBold {
    font-weight: 600;
  }

  &.bold {
    font-weight: bold;
  }

  &.italic {
    font-style: italic;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.underline {
    text-decoration: underline;
  }
}
