@use 'styles/constants.scss';
@use 'styles/colors.scss';

.chat-image-preview-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  > .close-icon {
    margin-top: constants.$l;
    margin-left: constants.$l;
    position: absolute;
    z-index: 9999;
  }

  > .image-preview {
    max-width: 80%;
    max-height: 80%;

    align-self: center;
    margin: auto 0;
  }

  &.isFullScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: colors.$colorsNeutralsDark450Clouds;
  }
}
