@use 'styles/colors';
@use 'styles/constants';
@use '../text/text.scss';

@mixin setColorTo($color) {
  color: $color;
  &:hover,
  &:focus {
    color: darkenOnHover($color);
  }
}

@mixin setBackgroundColorTo($color) {
  background-color: $color;
  &:hover,
  &:focus {
    background-color: darkenOnHover($color, 8%);
  }
}

$padding: 1.1rem 2.4rem;

button {
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
}

@mixin buttonStyle($backgroundColor, $textColor, $borderColor) {
  color: $textColor;
  font-size: 1.4rem;
  font-weight: bold;
  @include setBackgroundColorTo($backgroundColor);

  &.outline {
    color: $backgroundColor;
    background-color: transparent;
    border: 0.1rem solid $backgroundColor;
    @include setBackgroundColorTo($textColor);

    &.disabled {
      border-color: colors.$aviobookWebPrimaryZulu02;
      color: colors.$aviobookWebPrimaryZulu04;
    }

    &.loading {
      pointer-events: none;
      color: transparent;
      &:hover,
      &:focus {
        color: transparent;
      }

      .spinner-wrapper {
        margin: 0;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.button {
  &.neutral {
    @include buttonStyle(colors.$aviobookDarkSecondaryDelta, colors.$neutralsLight, colors.$neutralsLight);
  }

  &.primary {
    @include buttonStyle(colors.$aviobookDarkSecondaryDelta, colors.$coreWhite100, colors.$aviobookDarkSecondaryDelta);
  }

  &.negative {
    @include buttonStyle(
      colors.$aviobookDarkLegacySemanticWarning,
      colors.$coreWhite100,
      colors.$aviobookDarkLegacySemanticWarning
    );
  }

  &.dark {
    @include buttonStyle(colors.$aviobookLightPrimaryAlpha, colors.$coreWhite100, colors.$aviobookLightPrimaryAlpha);
  }

  &.disabled {
    color: colors.$aviobookWebPrimaryZulu04;
    background-color: #eef1f3;
  }
}

.as-text {
  background: transparent;
  &.neutral {
    @include setColorTo(colors.$aviobookWebNeutralsDarkDark01);
  }
  &.primary {
    @include setColorTo(colors.$aviobookDarkSecondaryDelta);
  }
  &.negative {
    @include setColorTo(colors.$aviobookDarkLegacySemanticWarning);
  }
}

.button,
.as-text {
  border-radius: 0.4rem;
  padding: $padding;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > :not(:first-child) {
    margin-left: 0.8rem;
  }

  &.loading {
    pointer-events: none;
    color: transparent;
    &:hover,
    &:focus {
      color: transparent;
    }

    .spinner-wrapper {
      margin: 0;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
