@use 'styles/colors.scss';
@use 'styles/constants.scss';

$modalWidth: 40rem;
$transitionDuration: 0.3s;

.side-modal-background {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: colors.$colorsNeutralsDark450Clouds;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .side-modal {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    min-width: $modalWidth;
    background-color: colors.$coreWhite100;
    padding: 2rem;
    transition: transform $transitionDuration ease-in-out;

    &.left {
      left: 0;
      transform-origin: left;
    }

    &.right {
      right: 0;
      transform-origin: right;
    }

    .side-modal-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .side-modal-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 2rem;
      margin-bottom: 2rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.6rem;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: colors.$colorsNeutralsDark450Clouds;
        border-radius: 0.3rem;
      }
    }

    .side-modal-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: auto;

      > :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
