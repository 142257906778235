.web-authorized-layout {
  display: flex;
  /* Set flex properties for Outlet */
  & > :first-child {
    max-width: 400px;
    flex-basis: 33.33%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  /* Set flex properties for Chat */
  & > :last-child {
    flex-basis: 66.66%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
