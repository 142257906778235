@use 'styles/colors';
@use 'styles/constants';

.scrollable-table {
  table {
    display: flex-block;
    width: 100%;
    margin-top: constants.$l;

    tr {
      &:nth-child(even) {
        background-color: colors.$aviobookCMSTableRow;
      }

      &:nth-child(odd) {
        background-color: colors.$coreWhite100;
      }
    }

    thead {
      border-top-left-radius: constants.$s;
      border-top-right-radius: constants.$s;
      overflow: hidden;

      th {
        top: 0;
        position: sticky;
        height: 3.5rem;
        border: constants.$xxs solid colors.$aviobookLightPrimaryBravo;
        background-color: colors.$aviobookWebSecondaryVictorVictor04;
        vertical-align: middle;
        text-align: start;
        padding-left: 1.6rem;
        color: colors.$aviobookLightPrimaryAlpha;
        font-size: 1.2rem;
        text-transform: uppercase;
        border-top-left-radius: constants.$s;
        border-top-right-radius: constants.$s;
        overflow: hidden;
      }
    }

    tbody {

      td {
        height: 3.5rem;
        border: constants.$xxs solid colors.$aviobookLightPrimaryBravo;
        vertical-align: middle;
        padding: 0.7rem 0 0.7rem 1.6rem;
        background-color: colors.$coreWhite100;

      }
    }
  }
}
