@use 'styles/colors';

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  background-color: colors.$coreWhite100;
  width: 50rem;
  height: auto;
  padding: 3rem;
  text-align: center;

  .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}

.modal-buttons {
  padding-top: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}