@use 'styles/colors.scss';
@use 'styles/constants.scss';

.avatar {
  align-items: center;
  background-color: colors.$aviobookDarkPrimaryCharlie;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 10rem;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}
